
import { Component } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';
import constrictionImg from '@/assets/construction.svg';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';

@Component({
  components: { PwrBtn }
})
export default class SiteUnderConstructionView extends PwrVue {
  public static ackKey = 'SITE_UNDER_CONSTRUCTION_ACK';

  get img() {
    return constrictionImg;
  }

  private async onContinueAnyway(): Promise<void> {
    localStorage.setItem(SiteUnderConstructionView.ackKey, 'true');
    await this.goBack();
  }
}
